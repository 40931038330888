import React, { useRef, useState } from 'react';

import placeholderUser from 'assets/icons/placeholder-user.svg';
import { styled } from 'styles';
import PopMenu from 'components/PopMenu';
import Button from 'components/Button';
import useAuthContext from 'hooks/useAuthContext';
import Icon from 'components/Icon';
import clsx from 'clsx';

const HeaderAvatarMenu = props => {
  const { logout, profile } = useAuthContext();
  const [open, setOpen] = useState(false);
  const buttonRef = useRef(null);
  const userPhoto = profile.avatar || placeholderUser;

  const handleMenuButtonClick = () => {
    setOpen(false);
    logout();
  };

  const handlePopMenuOutsideClick = e => {
    if (open && !(buttonRef.current as any).contains(e.target)) setOpen(false);
  };

  return (
    <>
      <StyledAvatarButton
        ref={buttonRef}
        className={clsx('avatar-button', { open })}
        onClick={() => setOpen(v => !v)}
        {...props}>
        <img className="user-photo" src={userPhoto} alt="avatar" data-cy="avatar" />{' '}
        <Icon name="chevron" />
      </StyledAvatarButton>
      <StyledPopMenu
        open={open}
        target={buttonRef.current}
        placement="bottom-end"
        onOutsideClick={handlePopMenuOutsideClick}>
        <Button simple onClick={handleMenuButtonClick}>
          Logout
        </Button>
      </StyledPopMenu>
    </>
  );
};

export default HeaderAvatarMenu;

const StyledAvatarButton = styled.button`
  display: flex;
  align-items: center;
  padding: 0;
  border: 0;
  background: none;
  outline: none;

  &:hover {
    opacity: 0.8;
  }

  .user-photo {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid ${props => props.theme.colors.lightGray};
    cursor: pointer;
    flex-shrink: 0;
  }

  .icon-chevron {
    margin-left: 4px;
  }

  &.open .icon-chevron {
    transform: rotate(180deg);
  }
`;

const StyledPopMenu = styled(PopMenu)`
  background: white;
  border: ${props => props.theme.colors.borderColor};
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 1px 5px #ccc;
  padding: 10px;

  button {
    background: none;
    border: none;
  }
`;
